<template>
  <div :class="className">
    <van-nav-bar
      title="安全知识库"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-list :class="`${className}__list`">
      <van-cell
        v-for="(item, idx) in secureRecordsConf"
        :key="idx"
        is-link
        clickable
        :title="item.title"
        :class="`${className}__list-item`"
        :to="{ name: item.routerName, query: item.routerQuery || {} }"
        :border="idx !== secureRecordsConf.length - 1"
      >
        <template #title>
          <img class="cell-icon" :src="item.icon" alt="" />
          <span>{{ item.title }}</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "SecureRepository",
  data() {
    return {
      biaozhunguifan: require("../../assets/images/secure/biaozhunguifan.svg"),
      falvfagui: require("../../assets/images/secure/falvfagui.svg"),
      guizhangzhidu: require("../../assets/images/secure/guizhangzhidu.svg"),
      zhengfuwenjian: require("../../assets/images/secure/zhengfuwenjian.svg"),
      className: "secure-records-dispatch",
      secureRecordsConf: [
        {
          icon: require("../../assets/images/secure/falvfagui.svg"),
          title: "法律法规",
          routerName: "Laws",
          router: "laws"
        },
        {
          icon: require("../../assets/images/secure/zhengfuwenjian.svg"),
          title: "政策文件",
          routerName: "policydocument",
          router: "policydocument"
        },
        {
          icon: require("../../assets/images/secure/biaozhunguifan.svg"),
          title: "标准规范",
          routerName: "policystandard",
          router: "policystandard"
        },
        {
          icon: require("../../assets/images/secure/zhengfuwenjian.svg"),
          title: "规章制度",
          routerName: "regulations",
          router: "regulations"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      authRoutes: state => state.menu.roles
    })
  },
  created() {
    this.initSecureRecordsConf();
  },
  methods: {
    initSecureRecordsConf() {
      this.secureRecordsConf = this.secureRecordsConf.filter(v =>
        this.authRoutes.includes(v.router)
      );
    }
  }
};
</script>

<style lang="scss">
.secure-records-dispatch {
  &__list {
    .van-cell::after {
      border-bottom: 1px solid #ebebeb;
    }
  }
  &__list-item {
    padding: 15px 20px;
    .van-cell__left-icon {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -4px;
    }
    span {
      color: #333333;
      font-size: 15px;
    }
    .cell-icon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      position: relative;
      top: 1px;
    }
  }
}
</style>
